// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";

// GENERAL
body {
    overflow-x: clip;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    .inner-wrapper > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// SHOP-HEADER
.header-sentinel {
    clear: both;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

.shop-top-bar {
    font-size: 14px;
    position: relative;
    padding: 13px 0 14px;
    
    &:after {
        content: '';
        .center-lr();
        bottom: 0;
        width: 101vw;
        height: 1px;
        background-color: @border-color;
    }

    .inner {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;

        > * {
            height: 21px;
        }
    }
}

.header-shipment {
    .line-height14();
    color: @header-color;

    &:before {
        content: '\e91b';
        font-family: icomoon;
        float: left;
        margin-right: 10px;
        font-size: 20px;
    }
}

.translator-wrapper {
    width: 218px;

    .goog-te-gadget {
        font-size: 0;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    #google_translate_element {
        display: flex;
    }

    select {
        height: 21px;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 !important;
        cursor: pointer;
        font-size: 13px;

        &:hover {
            border-color: @header-color;
        }
    }
}

.contact-dropdown {
    position: relative;

    &:hover {

        .contact-dropdown-trigger {
            text-decoration: underline;
        }

        .contact-dropdown-wrapper {
            opacity: 1;
            visibility: visible;
        }
    }
}

.contact-dropdown-trigger {
    .line-height14();
    color: @header-color;
    cursor: pointer;

    &:before,
    &:after {
        font-family: icomoon;
    }

    &:before {
        content: '\e919';
        float: left;
        font-size: 20px;
        margin-right: 10px;
    }

    &:after {
        content: '\e917';
        float: right;
        font-size: 16px;
        margin-left: 4px;
    }
}

.contact-dropdown-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1001;
    background-color: white;
    padding: 24px;
    border-radius: 24px;
    .shadow();
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.top-contact {
    display: flex;
    gap: 32px;
}

.header-contact {
    display: flex;
    .line-height14();
    gap: 24px;

    a {
        color: @header-color;
        display: block;

        &:before {
            font-family: icomoon;
            font-size: 20px;
            float: left;
            margin-right: 10px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .phone:before {
        content: '\e918';
    }

    .email:before {
        content: '\e916';
    }
}

.header-social-links {
    display: flex;
    gap: 8px;
    .line-height14();

    a {
        color: @header-color;
        display: flex;

        &:after {
            font-family: icomoon;
            font-size: 24px;
        }

        &:hover {
            color: @main-color;
        }
    }

    .facebook-link:after {
        content: '\e913';
    }

    .instagram-link:after {
        content: '\e915';
    }
}

@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        margin-bottom: 24px;
        z-index: 1000;
        background-color: white;
        transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
        box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0);

        &.stuck {
            transform: translateY(-50px);
            box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.12);
            //z-index: 1000;

            .logo {
                transform: translateY(-20px) scale(.8);
            }

            #search-engine {
                transform: translateY(23px);
            }

            .header-tools-wrapper {
                transform: translateY(23px);
            }

            #main-bar {
                transform: translateY(-6px);

                &:after {
                    transform: scaleY(.79);
                }
            }
        }
    }
}

.shop-header-container {
    display: flex;
    align-items: center;
    height: 184px;
    gap: 32px;
    padding-left: 330px;
    position: relative;
    padding-bottom: 56px;
}

// LOGO
.logo {
    display: flex;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .2s ease-in-out;
    transform-origin: left top;

    img {
        display: block;
        height: 117px;
        width: 222px;
    }
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: 32px;
    margin-left: auto;
    transition: transform .2s ease-in-out;
}

@media (min-width: 1280px) {
    .tree-trigger {
        display: none;
    }

    .tree-canvas {
        display: none;
    }

    #cart-box,
    #wishlist-box, 
    a.b2b-link,
    a.log-button {
        position: relative;
        cursor: pointer;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        height: 32px;
        justify-content: center;
        color: @header-color;

        em {
            display: none;
        }

        &:before {
            font-family: icomoon;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 32px;
            line-height: 1;
        }

        span,
        b {
            line-height: 1.2;
            font-size: 12px;
            text-transform: uppercase;
            white-space: nowrap;
        }

        &:hover {
            color: @main-color;
        }
    }

    #cart-box {

        &:before {
            content: '\e910';
        }

        i {
            position: absolute;
            width: 16px;
            line-height: 16px;
            color: white;
            background-color: @main-color;
            font-weight: bold;
            font-size: 10px;
            text-align: center;
            border-radius: 50%;
            top: -5px;
            left: 22px;
            font-style: normal;
        }
    }

    #wishlist-box {

        &:before {
            content: '\e914';
        }

        &[data-wishlist-count="0"]:after {
            display: none;
        }

        &:after {
            content: attr(data-wishlist-count);
            position: absolute;
            width: 16px;
            line-height: 16px;
            color: white;
            background-color: @main-color;
            font-weight: bold;
            font-size: 10px;
            text-align: center;
            border-radius: 50%;
            top: -5px;
            left: 22px;
        }
    }

    a.log-button {

        &:before {
            content: '\e91c';
        }
    }

    a.b2b-link {

        &:before {
            content: '\e90e';
        }
    }
}

// SEARCH ENGINE
.search-engine-trigger,
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 488px;
        z-index: 1000;
        transition: transform .2s ease-in-out;
        margin-left: 56px;

        &.show {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-last-phrases {
                    display: none;
                }
            }
        }

        &.show-with-last-phrases {

            &.no-products .no-products-info,
            a.suggestions-advanced-search {
                display: none;
            }

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-result-categories,
                .suggestion-result-producers,
                .suggestion-result-products {
                    display: none;
                }
            }

            a.suggestions-advanced-search {
                display: block;
            }
        }

        &.no-products {

            .no-products-info {
                display: block;

                & + .suggestion-column-wrapper .suggestion-left-column:not(.hidden) {
                    margin-top: 10px;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 48px;
            padding: 0 56px 0 24px;
            background-color: white;
            border-radius: 24px;
            color: @header-color;
            font-size: 16px;
            font-family: @main-font-family;
            border: 1px solid @border-color;

            &::-webkit-input-placeholder {
                color: @header-color;
            }
            &::-moz-placeholder {
                color: @header-color;
            }
            &:-ms-input-placeholder {
                color: @header-color;
            }
            &:-moz-placeholder {
                color: @header-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 56px;
            height: 48px;
            color: @header-color;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: '\e91a';
                font-family: icomoon;
            }

            &:hover {
                color: @main-color;
            }
        }
    }
}

// SUGGESTIONS
#suggestions {
    position: absolute;
    top: ~"calc(100% + 10px)";
    left: 0;
    background-color: @light-bg;
    width: 720px;
    padding: 24px;
    .shadow();
    opacity: 0;
    visibility: hidden;
    border-radius: 24px;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-10px);

    .no-products-info {
        display: none;
        font-size: 18px;
        font-weight: bold;
        color: @header-color;
        width: 100%;
    }

    &.loading {

        > *:not(.suggestions-loading) {
            display: none;
        }

        .suggestions-loading {
            display: flex;
        }
    }
}

.suggestions-loading {
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: none;

    span {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

.suggestion-column-wrapper {
    display: flex;
    width: 100%;
}

.suggestion-left-column {
    width: 33%;
    flex-shrink: 0;

    &.hidden {
        display: none;
    }
}

.suggestion-result-categories,
.suggestion-result-producers,
.suggestion-result-products,
.suggestion-last-phrases {

    &.hidden {
        display: none;
    }

    &:before {
        content: attr(data-label);
        display: block;
        color: @header-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.suggestion-result-categories,
.suggestion-result-producers {
    padding-right: 30px;

    a {
        display: inline-block;
        padding: 2px 0;
        color: @font-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.suggestion-last-phrases {
    width: 100%;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        &:before {
            content: '\e802';
            font-family: icomoon;
            color: @header-color;
            float: left;
            width: 35px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &:hover {
            color: @header-color;
        }
    }

    li {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }
    }

    .delete-hint-suggestion {
        margin-left: auto;
        font-style: normal;
        color: @header-color;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        cursor: pointer;

        &:after {
            content: '\e803';
            font-family: icomoon;
        }

        &:hover {
            color: @error-color;
        }
    }
}

.suggestion-result-categories {
    margin-bottom: 22px;
}

.suggestion-result-products {
    flex-grow: 1;
}

.suggestion-product {
    margin-top: 10px;

    &:hover {
        
        b {
            text-decoration: underline;
        }
    }

    a {
        display: block;
        position: relative;
        min-height: 60px;
        padding-left: 80px;
    }

    .size-custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }

    b {
        color: @header-color;
        display: block;

    }

    span {
        color: @font-color;
    }
}

a.suggestions-advanced-search {
    width: 100%;
    margin-top: 12px;
    color: @header-color;

    &:hover {
        text-decoration: underline;
    }
}

// MAIN BAR
#main-bar {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 330px);
    display: flex;
    align-items: center;
    height: 56px;
    transition: transform .2s ease-in-out;

    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        inset: 0;
        background-color: @main-color;
        border-radius: 8px;
        transition: transform .2s ease-in-out;
    }
}

// MAINMENU
.mainmenu {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;

    li[title*="okazj"] {

        a {
            color: white !important;
            background-color: @sec-color;
            border-radius: 4px;

            @media (min-width: 1280px) {
                &:hover {
                    background-color: #84C23E;
                }
            }
        }
    }

    a {
        line-height: 32px;
        color: white;
        font-weight: bold;
        padding: 0 20px;
        display: block;
        white-space: nowrap;

        @media (min-width: 1280px) {
            &:hover {
                color: @header-color;
            }
        }
    }
}

// SIDEBAR
.shop-header.stuck + main .sidebar {
    z-index: 0;
}

.sidebar {
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    top: -31px;
    z-index: 1000;
}

// TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    #tree {
        background-color: #D7E7C5;
        border-radius: var(--borderRadius);
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &:before {
            content: 'Kategorie produktów';
            font-weight: bold;
            color: @header-color;
        }

        > nav {


        }

        li.t0 {
            border-bottom: 1px dashed rgba(54, 54, 53, 0.4);

            &:last-child {
                border-bottom: 0;
            }

            ul {
                padding: 6px 0;
            }
        }

        li {
            position: relative;

            &.downarrow {

                > a {
                    border-bottom: 1px dashed rgba(54, 54, 53, 0.4);
                }

                > ul {
                    display: block;
                }
            }
        
            ul {
                display: none;
            }
        }

        li.t0 {

            > a {
                display: block;
                color: @header-color;
                font-size: 14px;
                padding: 12px 0;
            }
        }

        a {
            position: relative;

            &:hover {
                text-decoration: underline;
            }
        }

        li:not(.t0) {

            a {
                display: block;
                font-size: 14px;
                color: @header-color;
                padding: 6px 0;
            }
        }

        li.selected > a {
            font-weight: bold;
            color: @sec-color;
        }

        li.t1 > a {
            padding-left: 12px;
        }

        li.t2 > a {
            padding-left: 24px;
        }

        li.t3 > a {
            padding-left: 36px;
        }

        button {
            display: none;
        }
    }
}

// SIDEBAR TABS
.sidebar-tabs {
    border-radius: var(--borderRadius);
    overflow: hidden;
    border: 1px solid @border-color;
    padding: 23px 23px 13px;

    .products-list-tabs {

        > strong {
            display: block;
            line-height: 20px;
        }
    }

    nav {
        display: flex;
        height: 20px;

        button {
            cursor: pointer;
            user-select: none;
            font-weight: bold;

            &:nth-child(2) {
                margin-left: 48px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    left: -23px;
                    width: 1px;
                    height: 100%;
                    background-color: @border-color;
                }
            }

            &[aria-selected="true"] {
                color: @main-color;
            }
        }
    }

    .products-inner {
    }
}

// SIDEBAR PRODUCTS
.sidebar-product {
    border-bottom: 1px dashed #97A18C;
    display: flex;
    height: 117px;
    align-items: center;

    &:last-child {
        border-bottom: 0;
    }

    > div {

        &:nth-child(1) {
            width: 55px;
            margin-right: 14px;
            flex-shrink: 0;
        }

        &:nth-child(2) {
            flex-grow: 1;
        }
    }

    &:hover .sidebar-product-name {
        text-decoration: underline;
    }
}

.sidebar-product-image {
    width: 100%;
    height: 55px; 
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        width: auto;
        height: auto;
    }
}

.sidebar-product-badge {
    display: block;
    color: white;
    background-color: @error-color;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
}

a.sidebar-product-name {
    font-size: 14px;
    line-height: 1.2;
    max-height: calc(3 * (1.2 * 14px));
    overflow: hidden;
    color: @header-color;
    font-weight: normal;
    display: block;

    &:first-letter {
        text-transform: uppercase;
    }
}

.sidebar-product-price {
    display: block;
    margin-top: 8px;
    font-size: 14px;

    b,
    del {

        &:after {
            content: ' zł';
        }
    }

    b {
        font-weight: 700;
        color: @sec-color;
    }

    del {
        color: #999999;
        margin-left: 8px;
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: 55px var(--prodGap);
    width: 100%;

    &.wide {
        grid-template-columns: repeat(var(--prodSwiperCols), minmax(0, 1fr));
        gap: 55px var(--prodSwiperGap);

        .product {

            @media (min-width: 1280px) {
                &:after {
                    right: calc(var(--prodSwiperGap) / 2 * -1);
                }

                &:nth-child(5n):after {
                    display: none;
                }
            }
        }
    }

    &:not(.wide) {

        .product {

            @media (min-width: 1280px) {
                &:after {
                    right: calc(var(--prodGap) / 2 * -1);
                }

                &:nth-child(4n):after {
                    display: none;
                }
            }
        }
    }

    .product {

        @media (min-width: 1280px) {
            &:after {
                content: '';
                width: 1px;
                height: 100%;
                border-right: 1px dashed #97A18C;
                position: absolute;
                top: 0;
                
            }

            &:last-child {

                &:after {
                    display: none;
                }
            }
        }
    }
}

.product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 16px;
    text-align: center;
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform .1s ease-in-out;

    img {

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            display: none;
            opacity: 0;
        }
    }

    .product-description {
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        color: @header-color;
        background-color: rgba(255,255,255,.9);
        padding: 16px;
        font-size: 14px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
        transition: all .2s ease-in-out;

        p {
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            max-height: calc(4 * (14px * var(--lineHeight)));
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

a.product-name {
    font-weight: normal;
    display: block;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    font-size: 14px;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 4px;
    z-index: 1;
}

.product-price {
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    line-height: 1.2;
    align-items: flex-start;

    b, del {

        &:after {
            content: ' zł';
        }
    }

    b {
        color: @sec-color;
    }

    del {
        color: #999999;
    }
}

.product-add {
    width: 80px;
    background-color: @main-color;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    gap: 8px;

    &:before {
        content: '\e910';
        font-family: icomoon;
        font-weight: normal;
        font-size: 20px;
    }

    &.disabled {
        display: none;
    }

    &:hover {
        background-color: @sec-color;
    }
}

.product-wishlist {
    margin-left: auto;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D7E7C5;
    border-radius: 4px;
    font-size: 20px;
    color: @header-color;

    &:after {
        content: '\e914';
        font-family: icomoon;
    }

    &:hover {
        color: @sec-color;
    }

    &.existing {
        color: @sec-color;
        background-color: darken(#D7E7C5, 10%);
    }
}

.product-badges {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-transform: uppercase;
    gap: 2px;

    span {
        color: white;
        background-color: @sec-color;
        font-size: 12px;
        font-weight: bold;
        line-height: 22px;
        padding: 0 8px;

        &.promo-badge {
            background-color: @error-color;
        }
    }
}

@media (min-width: 1280px) {
    .product:hover {

        .product-name {
            text-decoration: underline;
        }

        .product-description {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
        }

        &:nth-of-type(2) {
            display: block;
            animation: productImageOpacity .2s ease-in-out forwards;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    margin-bottom: 24px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 10px;
        }

        &:last-child {
            font-weight: bold;

            &:after {
                display: none;
            }

            a,
            span {
                color: @main-color;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// LOADER
#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}